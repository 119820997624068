import { Injectable, signal } from "@angular/core";
import { APP_MENUS } from "../app-shared/service/app-menu-helper.service";

@Injectable({providedIn:'root'})
export class AppUserMenuService{
  currentMenuTitle = signal('');
  setMenuTitle(mnuId: string){
    var mnuItem = APP_MENUS.find(i=>i.mnuId === mnuId);
    if (mnuItem){
      let mnuName = mnuItem.mnuId + "-" + mnuItem.mnuName;
      this.currentMenuTitle.set(mnuName);
    }
    else{
      this.currentMenuTitle.set('Không thấy menu, vui lòng báo Admin');
    }
  }
}
